




































import { Component, Vue, Prop } from "vue-property-decorator";
import UIkit from "uikit";

@Component
export default class Layout extends Vue {
  @Prop({ default: false }) customizable!: boolean;

  protected toggleModal(): void {
    UIkit.modal(document.getElementById(this.customId) as HTMLElement).show();
  }

  get customId(): string {
    return this.$slots.default
      ? this.$slots
          .default![0].text!.trim()
          .replaceAll(" ", "-")
          .replaceAll("/", "")
          .toLowerCase()
      : "info-modal";
  }
}
