import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import * as option from "@/models/configurator/configurator_options";
import { FabricSwatch } from "@/models/products/fabric";
import { LayoutStore, CanopyStore } from "@/mixins/store";
import FabricService from "@/services/fabric_service";
import { ProductLineName } from "@/models/products/collection";

@Component
export class LayoutRules extends mixins(LayoutStore, CanopyStore) {
  protected whiteFabric: null | FabricSwatch = null;
  protected ProductLineName = ProductLineName;
  protected fabricService = new FabricService();

  /** LAYOUT RULES THAT APPLY TO LAYOUT SWITCH ONLY
   * Called from Layout.vue on user clicking new layout
   *
   */
  protected async applyLayoutRules(): Promise<void> {
    const res = await this.fabricService.getSingleFabric("R-099");
    this.whiteFabric = res;
    // if double-vented, need to set default middle vent fabric
    if (
      this.preset === option.Preset.S1DV ||
      this.preset === option.Preset.D5
    ) {
      this.addVentMiddle(option.Panel.Solid);
      this.addVentMiddleFabric([this.mainFabric[0]]);
    } else {
      this.addVentMiddleFabric([]);
    }
    // designer layouts do not have valance or alternating panels
    if (
      this.preset !== option.Preset.S1 &&
      this.preset !== option.Preset.S1DV
    ) {
      this.removeValance();
      this.makeCanopiesSolid();
      this.removeBindingFabric();
    } else {
      this.clearAllTrim();
    }
    /**
     * The following switch sets preset configurations of designer umbrellas
     *
     * Each designer layout offers a different combination of trim fabric locations
     *
     * The goal is for the user to see a visual change on the umbrella when they toggle between different designer presets, so this logic
     * sets all trim fabric to white R-099 (or gray R-164 if main fabric is white) and then saves these changes to the store
     *
     * If the trim fabric is already set, do nothing except clear the non-applicable trim fabric data.
     *
     * D2 & D3 differ in the viewer display, but have the same data
     *
     */
    switch (this.preset) {
      case option.Preset.D1: {
        this.addMainTrimFabricInner(null);
        this.addVentTrimFabric(null);
        if (!this.mainTrimFabric) {
          // only execute if trim fabric is not set
          let swatch = this.whiteFabric!;
          if (this.mainFabric[0].mfr_code === this.whiteFabric!.mfr_code) {
            const res = await this.fabricService.getSingleFabric("R-164");
            swatch = res;
          }
          this.addMainTrimFabric(swatch);
          await this.$viewer.ChangeCanopyTrim(swatch.mfr_code, null);
        }
        break;
      }
      case option.Preset.D2:
      case option.Preset.D3: {
        this.addVentTrimFabric(null);
        if (!this.mainTrimFabric) {
          // only execute if trim fabric is not set
          let swatchOuter = this.whiteFabric!;
          if (this.mainFabric[0].mfr_code === this.whiteFabric?.mfr_code) {
            const res = await this.fabricService.getSingleFabric("R-164");
            swatchOuter = res;
          }
          this.addMainTrimFabric(swatchOuter);
        }
        if (!this.mainTrimFabricInner) {
          // only execute if trim fabric is not set
          let swatchInner = this.whiteFabric!;
          if (this.mainFabric[0].mfr_code === this.whiteFabric?.mfr_code) {
            const res = await this.fabricService.getSingleFabric("R-164");
            swatchInner = res;
          }
          this.addMainTrimFabricInner(swatchInner);
        }
        await this.$viewer.ChangeCanopyTrim(
          this.mainTrimFabric!.mfr_code,
          this.mainTrimFabricInner!.mfr_code
        );
        break;
      }
      case option.Preset.D4: {
        this.addMainTrimFabric(null);
        this.addMainTrimFabricInner(null);
        if (!this.ventTrimFabric) {
          // only execute if trim fabric is not set
          let swatch = this.whiteFabric!;
          if (this.ventTopFabric[0].mfr_code === this.whiteFabric!.mfr_code) {
            const res = await this.fabricService.getSingleFabric("R-164");
            swatch = res;
          }
          this.addVentTrimFabric(swatch);
          await this.$viewer.ChangeVentTrim(this.ventTrimFabric!.mfr_code);
        }
        break;
      }
      case option.Preset.D5: {
        this.addMainTrimFabricInner(null);
        if (!this.ventTrimFabric) {
          // only execute if trim fabric is not set
          let swatch = this.whiteFabric!;
          if (this.ventTopFabric[0].mfr_code === this.whiteFabric!.mfr_code) {
            const res = await this.fabricService.getSingleFabric("R-164");
            swatch = res;
          }
          this.addVentTrimFabric(swatch);
          await this.$viewer.ChangeVentTrim(this.ventTrimFabric!.mfr_code);
        }
        if (!this.mainTrimFabric) {
          // only execute if trim fabric is not set
          let swatch = this.whiteFabric!;
          if (this.mainFabric[0].mfr_code === this.whiteFabric!.mfr_code) {
            const res = await this.fabricService.getSingleFabric("R-164");
            swatch = res;
          }
          this.addMainTrimFabric(swatch);
          await this.$viewer.ChangeCanopyTrim(
            this.mainTrimFabric!.mfr_code,
            null
          );
        }
        break;
      }
    }
  }

  protected async removeBindingFabric(): Promise<void> {
    this.addBindingFabric(null);
    await this.$viewer.ChangeBindingFabric(null);
  }

  protected async removeValance(): Promise<void> {
    this.addValance(option.Valance.Canopy);
    await this.$viewer.ChangeValance(option.Valance.Canopy);
  }

  protected async makeCanopiesSolid(): Promise<void> {
    this.addMainFabric([this.mainFabric[0]]);
    await this.$viewer.ChangeCanopyFabric(
      this.mainFabric[0].fabric_scale,
      this.mainFabric[0].mfr_code
    );
    this.addVentMiddle(option.Panel.Solid);
    this.addMainCanopy(option.Panel.Solid);
  }

  protected clearAllTrim(): void {
    this.addMainTrimFabric(null);
    this.addMainTrimFabricInner(null);
    this.addVentTrimFabric(null);
  }
}
