



































































































































































import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/designer/sidebar/layout/Layout.vue";
import { mixins } from "vue-class-component";
import { CanopyStore, LayoutStore, ShapeStore } from "@/mixins/store";
import S1SVG from "../../../../assets/images/S1.svg?inline";
import S1DVSVG from "../../../../assets/images/S1DV.svg?inline";
import D1SVG from "../../../../assets/images/D1.svg?inline";
import D2SVG from "../../../../assets/images/D2.svg?inline";
import D3SVG from "../../../../assets/images/D3.svg?inline";
import D4SVG from "../../../../assets/images/D4.svg?inline";
import D5SVG from "../../../../assets/images/D5.svg?inline";
import { LayoutRules } from "@/mixins/business_logic/layout/layout_rules";
import { Preset } from "@/models/configurator/configurator_options";

import { ProductLineName } from "@/models/products/collection";
@Component({
  components: {
    Layout,
    D1SVG,
    D2SVG,
    D3SVG,
    D4SVG,
    D5SVG,
    S1SVG,
    S1DVSVG,
  },
})
export default class LayoutSidebar extends mixins(
  LayoutStore,
  CanopyStore,
  ShapeStore,
  LayoutRules
) {
  @Prop() isStockOnly!: boolean;
  @Prop({ default: false }) customizable!: boolean;
  @Prop() modalId!: string;
  @Prop({ default: true }) hasVent!: boolean;
  @Prop({ default: true }) hasValance!: boolean;
  protected Preset = Preset;
  protected ProductLineName = ProductLineName;
  protected disableDesignerLayouts = [
    ProductLineName.Avalon,
    ProductLineName.Catalina,
    ProductLineName.Laurel,
    ProductLineName.EmeraldCoast,
  ];
  protected disableDoubleVent = [
    ProductLineName.Eclipse,
    ProductLineName.Avalon,
    ProductLineName.Catalina,
    ProductLineName.Laurel,
  ];

  mounted() {
    this.readStoreData();
  }

  protected showDoubleVentLayouts(handle: string): boolean {
    if (this.disableDoubleVent.includes(handle as ProductLineName)) {
      return true;
    } else if (this.hasVent) {
      return false;
    } else {
      return true;
    }
  }

  protected showDesignerLayouts(handle: string): boolean {
    return this.disableDesignerLayouts.includes(handle as ProductLineName);
  }

  protected readStoreData(): void {
    if (!this.preset) {
      this.addPreset(Preset.S1);
    }
    if (!this.umbrellaModel) {
      this.$router.push("/");
    }
  }

  protected async select(layout: Preset): Promise<void> {
    if (this.preset !== layout) {
      // prevent sending layout change when no change happens; this bugs the viewer sometimes
      this.addPreset(layout);
      await this.$viewer.ChangeCanopyLayout(layout);
      await this.applyLayoutRules();
    }
  }
}
